import { createStore } from 'vuex'

export default createStore({
    state: {
        baseUrl: 'https://greenpastures.bi/api/',
        
        // baseUrl: 'https://v2.greenpastures.bi/api/',

        // baseUrl: 'http://127.0.0.1:8000/api/',

        igisomwaUnomusi_1year: [],
        igisomwaUnomusi_2year: [],
        ibibazo: [],
        twebwe: [],
        ibisomwa_1year: [],
        ibisomwa_2year: [],
        intangamarara: [],
        all_meditations: [],
    },
    mutations: {

    },
    
    actions: {
    },
    modules: {
    },
    getters:{
    },
    computed:{
    }
})






