<template>
  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Sangiza abandi</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <br>

      <div class="card-igisomwa">
        <ion-item>
          <ion-list>
            <ion-label>
              <h3 @click="shareContent">Sangiza Green Pastures
                App</h3>
            </ion-label>
          </ion-list>
        </ion-item>
      </div>

      <div class="card-image">
        <a href="https://play.google.com/store/apps/details?id=com.egeratech.greenpastures" target="_blank"><img
            src="/static/storeGoogle.png" loading="lazy" alt="Google Play" /></a>

        <a href="https://apps.apple.com/app/green-pastures-app/id6458740624" target="_blank"><img
            src="/static/storeApple.png" loading="lazy" alt="App Store" /></a>
      </div>



    </ion-content>
  </ion-page>
</template>
  
  
<script>


export default {
  name: 'sangiza-abandi',
  components: {
  },
  data() {
    return {
      loader: true,
      intangamarara: [],
    }
  },
  methods: {
    shareContent() {
      if (navigator.share) {
        navigator.share({
          url: 'https://play.google.com/store/apps/details?id=com.egeratech.greenpastures'
          // The URL for android
          // url: 'https://apps.apple.com/app/green-pastures-app/id6458740624'
          // The URL for iOS
        })
          .then(() => {
            console.log('Sharing successful');
          })
          .catch((error) => {
            console.error('Error sharing:', error);
            this.$toast.error('Error sharing:');
          });
      } else {
        console.log('Web Share API not supported.');
        this.$toast.error('Web Share API not supported.');
      }
    }
  },
  mounted() {
  },
}
</script>

<style>
.card-image{
  display: flex;
  justify-content: center;
  gap: 10px;
}
</style>