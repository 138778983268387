<template>
  
  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Twebwe</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">


      <div v-if="loader && this.$store.state.twebwe == 0">
        <div class="card-igisomwa">
          <OurLoading />
        </div>
      </div>

      <div @click="close" v-if="success">
        <OurSuccess />
      </div>

      <div v-for="(item, index) in this.$store.state.twebwe" :key="index">
        <div class="card-igisomwa">
          <h2>Kahise kacu</h2>
          <pre v-html="formatItem(item.kahise_kacu)"></pre>
          <iframe :src="item.kahise_kacu_youtube_link" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <h2>Ukwo dusoma</h2>
          <pre v-html="formatItem(item.ukwo_dusoma)"></pre>
          <iframe :src="item.ukwo_dusoma_youtube_link" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <br>
          <br>

          <h2>Kwiyandikisha mubiga ivyizerwa</h2>
          <form class="from-ivyizerwa" @submit.prevent="save_MemberInDoctrine">
            <input type="text" placeholder="Izina" v-model="form.izina" required>
            <input type="text" placeholder="Amatazirano" v-model="form.amatazirano" required>

            <input type="number" placeholder="Telefone" v-model="form.phone" required>
            <input type="text" placeholder="Aho usengera" v-model="form.aho_asengera">
            <input type="text" placeholder="Umwaka (ak: 2024)" v-model="form.promotion">
            <input type="text" placeholder="Aho uba" v-model="form.aho_aba">


            <button type="submit" class="submit">Rungika</button>
          </form>

        <br>

        <ion-list>
          <ion-item>
            <div class="socio">
              <a href="https://www.facebook.com/profile.php?id=100064842694500"><i class="icon-facebook"
                  aria-hidden="true"></i></a>
              <a href="https://twitter.com/BurundiFlock"><i class="icon-twitter"></i></a>
              <a href="https://www.youtube.com/channel/UCbjZ4CtsHcdz5E8-HatdAhg/videos"><i class="icon-youtube"></i></a>
              <a href="https://www.instagram.com/littleflockburundi/"><i class="icon-instagram"></i></a>
            </div>
          </ion-item>
        </ion-list>
      </div>
    </div>


    </ion-content>
  </ion-page>
</template>


<script>
import OurLoading from '@/components/OurLoading.vue'


export default {
  name: 'AboutView',
  components: {
    OurLoading,
  },
  data() {
    return {
      loader: true,
      success: false,
      form: {
        izina: '',
        amatazirano: '',
        phone: '',
        aho_asengera: '',
        promotion: '',
        aho_aba: '',
      },

    }
  },
  methods: {
    save_MemberInDoctrine() {
      console.log(this.form)
      this.axios.post(this.$store.state.baseUrl + 'abiga-ivyizerwa/', this.form)
        .then(response => {
          console.log(response)
          this.$toast.success(`Warungitse!`)

          this.form = {}
        }).catch(err => {
          console.log(err)
          this.$toast.error(err)
        })
      this.topFunction()
    },
    loadTwebwe() {
      this.axios.get(this.$store.state.baseUrl + 'twebwe/').then(
        response => {
          this.$store.state.twebwe = response.data.results;
          this.loader = false;
        }
      )
    },
    formatItem(text) {
      // Regular expression to identify links
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      // Replace the links with anchor tags
      return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    }


  },
  // created(){
  mounted() {
    this.loadTwebwe();
  },
}
</script>

<style></style>