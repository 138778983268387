<template>
    <ion-page id="main-content">

        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button text="" defaultHref="/"></ion-back-button>
                </ion-buttons>
                <ion-title>Kurimbura</ion-title>
                <ion-buttons slot="end">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="meditation">
                <i v-if="showBackButton" @click="showAllItems" class="fas fa-arrow-left back-icon"></i>
                <ion-list>
                    <ion-item v-for="(buttonKey, index) in visibleButtons" :key="index" @click="search(buttonKey)"
                        detail='true'>
                        <ion-label>
                            {{ buttonKey }}
                        </ion-label>
                    </ion-item>
                </ion-list>
                <br>

                <div v-if="selectedItem">
                    <div v-if="meditations_tot === 0" class="card-igisomwa">
                        <p>Ntanyishu ihari !</p>
                    </div>
                    <div v-else>
                        <div v-for="(meditation, index) in $store.state.all_meditations" :key="index">
                            <router-link class="link-rondera" :to="`/meditationDetail/${meditation.id}`">
                                <ion-list>
                                    <ion-item detail='true'>
                                        <ion-label>
                                            <h2>Kurimbura {{ meditation.igisomwa_name }}</h2>
                                            <p>{{ meditation.meditation.slice(0, 40) + "..." }}</p>
                                        </ion-label>
                                    </ion-item>
                                </ion-list>

                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>


<script>

export default {
    name: 'rondera-meditation',
    components: {
    },
    data() {
        return {
            loader: false,
            meditations_tot: [],
            selectedItem: '',
            buttons: {
                // Pentateuch
                Itanguriro: true,
                Kuvayo: true,
                Abalewi: true,
                Guharura: true,
                "Gusubira muvyagezwe": true,

                // Historical Books
                Yosuwa: true,
                Abacamanza: true,
                Rusi: true,
                "1 Samweli": true,
                "2 Samweli": true,
                "1 Abami": true,
                "2 Abami": true,
                "1 Ingoma": true,
                "2 Ingoma": true,

                "Ezira": true,
                Nehemiya: true,
                Esiteri: true,

                // Wisdom Books
                Yobu: true,
                Zaburi: true,
                Imigani: true,

                Umusiguzi: true,
                Indirimbo: true,

                // Prophets
                Yesaya: true,
                Yeremiya: true,
                "Gucura intimba": true,
                Ezekiyeli: true,
                Daniyeli: true,
                Hoseya: true,
                Yoweli: true,
                Amosi: true,
                Obadiya: true,
                Yona: true,
                Mika: true,
                Nahumu: true,
                Habakuki: true,
                Zefaniya: true,
                Hagayi: true,
                Zekariya: true,
                Malaki: true,

                //Ubutumwa bwiza
                Matayo: true,
                Mariko: true,
                Luka: true,
                Yohana: true,

                "Ivyakozwe n’intumwa": true,

                //Ivyete
                Abaroma: true,
                "1 Ab’i korinto": true,
                "2 Ab’i korinto": true,
                "Ab’i galatiya": true,
                Abanyefeso: true,
                "Ab’i filipi": true,
                "Ab’i kolosayi": true,
                "1 Ab’i tesalonike": true,
                "2 Ab’i tesalonike": true,
                "1 Timoteyo": true,
                "2 Timoteyo": true,
                Tito: true,
                Filemoni: true,

                // General Epistles
                Abaheburayo: true,
                Yakobo: true,
                "1 Petero": true,
                "2 Petero": true,

                // Johannine Epistles
                "1 Yohani": true,
                "2 Yohani": true,
                "3 Yohani": true,
                Yuda: true,

                // Prophecy
                "Ivyahishiwe Yohana": true,
            },
            showBackButton: false,
        };
    },
    methods: {
        search(searchText) {
            this.loader = true;

            this.axios
                .get(this.$store.state.baseUrl + `v2/all-meditations/?search=${searchText}`)
                .then((response) => {
                    this.$store.state.all_meditations = response?.data?.results;
                    this.meditations_tot = response?.data?.count;
                    this.loader = false;

                    // Set selected item for details display
                    this.selectedItem = searchText;

                    // Hide all buttons except the clicked one
                    for (const button in this.buttons) {
                        this.buttons[button] = button === searchText;
                    }

                    this.showBackButton = true;
                })
                .catch((error) => {
                    console.log(error);
                    this.loader = false; // Turn off loader in case of error
                });
        },
        showAllItems() {
            this.selectedItem = '';
            for (const button in this.buttons) {
                this.buttons[button] = true;
            }
            this.showBackButton = false;
        },
    },
    computed: {
        visibleButtons() {
            return Object.keys(this.buttons).filter((button) => this.buttons[button]);
        },
    },
    mounted() {
    },
};
</script>

<style>
.meditation {
    margin: 1rem 0 0 0;
}
</style>
