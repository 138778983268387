<template>
    <ion-page id="main-content">

        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button text="" defaultHref="/"></ion-back-button>
                </ion-buttons>
                <ion-title>Intangamarara</ion-title>
                <ion-buttons slot="end">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <br>
            <div v-if="loader && intangamarara == 0">
                <OurLoading />
            </div>

            <div v-else>
                <div class="card-igisomwa">

                    <iframe :src="intangamarara.youtube_link" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                    <h2>Intangamarara y'igitabo:</h2>
                    <h3>{{ intangamarara.igitabo }}</h3>
                    <pre v-html="formatInyishu(intangamarara.message)"></pre>
                </div>
            </div>

        </ion-content>
    </ion-page>
</template>

<script>
// import axios from 'axios'

import OurLoading from '@/components/OurLoading.vue'


export default {
    name: 'intangamarara-view',
    // props: ['igisomwa_id'],
    components: {
        OurLoading,
    },
    data() {
        return {
            loader: true,
            intangamarara: ''
        }
    },
    methods: {
        getintangamarara() {
            this.loader = true
            this.axios.get(this.$store.state.baseUrl + 'v2/intangamarara/' + this.$route.params.id + '/')
                .then(
                    resp => {
                        console.log(resp)
                        this.intangamarara = resp.data
                        this.loader = false
                    }).catch(error => {
                        console.log(error)
                    });
        },
        formatInyishu(text) {
            // Regular expression to identify links
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            // Replace the links with anchor tags
            return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
        }

    },
    mounted() {
        this.getintangamarara();
    },
    computed: {
    }
}
</script>
  
  
<style></style>
  