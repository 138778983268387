<template>
  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Kurimbura</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <br>
      <div v-if="loader == true">
        <OurLoading />
      </div>

      <div v-else>
        <div v-if="meditations_tot === 0" class="card-igisomwa">
          <p>Ntanyishu ihari !</p>
        </div>
        <div v-else>
          <div v-for="(meditation, index) in meditations" :key="index">
            <router-link class="link-rondera" :to="`/meditationDetail/${meditation.id}`">
              <ion-list>
                <ion-item detail='true'>
                  <ion-label>
                    <h2>Kurimbura {{ meditation.igisomwa_name }}</h2>
                    <p>{{ meditation.meditation.substring(0, 40) + "..." }}</p>
                  </ion-label>
                </ion-item>
              </ion-list>
            </router-link>
          </div>
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script>
import OurLoading from '@/components/OurLoading.vue'

export default {
  name: 'meditation-view',
  components: {
    OurLoading,
  },
  data() {
    return {
      loader: true,
      meditations: [],
      meditations_tot: []
    }
  },
  methods: {
    getMeditation() {
      this.axios.get(this.$store.state.baseUrl + 'v2/meditation/?search=' + this.$route.params.id).then(
        response => {
          this.meditations = response?.data?.results;
          this.meditations_tot = response?.data?.count;
          this.loader = false;
        }
      ) //.finally(this.loadIntangamarara())
    },
  },
  mounted() {
    this.getMeditation();
  },
}
</script>


<style></style>