<template>
    <div v-if="loader">
        <OurLoading />
    </div>

    <div v-else>
        <i v-if="showBackButton" @click="showAllItems" class="fas fa-arrow-left"></i>
        <ion-list>
            <ion-item v-for="(buttonKey, index) in visibleButtons" :key="index" @click="search(buttonKey)" detail='true'>
                <ion-label>
                    {{ buttonKey }}
                </ion-label>
            </ion-item>
        </ion-list>
        <br>

        <div v-if="selectedItem">
            <div v-for="(igisomwa, index) in $store.state.ibisomwa_1year" :key="index">
                <router-link class="link-rondera" :to="`/igisomwa/${igisomwa.id}`">
                    <ion-list>
                        <ion-item detail='true'>
                            <ion-label>
                                <h2>Igisomwa: {{ igisomwa.igisomwa }}</h2>
                                <p>{{ igisomwa.kristo_mugisomwa.substring(0, 40) + "..." }}</p>
                            </ion-label>
                        </ion-item>
                    </ion-list>

                </router-link>
            </div>
        </div>
    </div>
</template>


<script>
import OurLoading from '@/components/OurLoading.vue';

export default {
    name: 'rondera-igisomwa',
    components: {
        OurLoading,
    },
    data() {
        return {
            loader: false,
            selectedItem: '',
            buttons: {
                // Pentateuch
                Itanguriro: true,
                Kuvayo: true,
                Abalewi: true,
                Guharura: true,
                "Gusubira muvyagezwe": true,

                // Historical Books
                Yosuwa: true,
                Abacamanza: true,
                Rusi: true,
                "1 Samweli": true,
                "2 Samweli": true,
                "1 Abami": true,
                "2 Abami": true,
                "1 Ingoma": true,
                "2 Ingoma": true,

                "Ezira": true,
                Nehemiya: true,
                Esiteri: true,

                // Wisdom Books
                Yobu: true,
                Zaburi: true,
                Imigani: true,

                Umusiguzi: true,
                Indirimbo: true,

                // Prophets
                Yesaya: true,
                Yeremiya: true,
                "Gucura intimba": true,
                Ezekiyeli: true,
                Daniyeli: true,
                Hoseya: true,
                Yoweli: true,
                Amosi: true,
                Obadiya: true,
                Yona: true,
                Mika: true,
                Nahumu: true,
                Habakuki: true,
                Zefaniya: true,
                Hagayi: true,
                Zekariya: true,
                Malaki: true,

                //Ubutumwa bwiza
                Matayo: true,
                Mariko: true,
                Luka: true,
                Yohana: true,

                "Ivyakozwe n’intumwa": true,

                //Ivyete
                Abaroma: true,
                "1 Ab’i korinto": true,
                "2 Ab’i korinto": true,
                "Ab’i galatiya": true,
                Abanyefeso: true,
                "Ab’i filipi": true,
                "Ab’i kolosayi": true,
                "1 Ab’i tesalonike": true,
                "2 Ab’i tesalonike": true,
                "1 Timoteyo": true,
                "2 Timoteyo": true,
                Tito: true,
                Filemoni: true,

                // General Epistles
                Abaheburayo: true,
                Yakobo: true,
                "1 Petero": true,
                "2 Petero": true,

                // Johannine Epistles
                "1 Yohani": true,
                "2 Yohani": true,
                "3 Yohani": true,
                Yuda: true,

                // Prophecy
                "Ivyahishiwe Yohana": true,
            },
            showBackButton: false,
        };
    },
    methods: {
        search(searchText) {
            this.loader = true;

            this.axios
                .get(this.$store.state.baseUrl + `v2/ibisomwa_1year/?search=${searchText}`)
                .then((response) => {
                    this.$store.state.ibisomwa_1year = response?.data?.results;
                    this.loader = false;

                    // Set selected item for details display
                    this.selectedItem = searchText;

                    // Hide all buttons except the clicked one
                    for (const button in this.buttons) {
                        this.buttons[button] = button === searchText;
                    }

                    this.showBackButton = true;
                })
                .catch((error) => {
                    console.log(error);
                    this.loader = false; // Turn off loader in case of error
                });
        },
        showAllItems() {
            this.selectedItem = '';
            for (const button in this.buttons) {
                this.buttons[button] = true;
            }
            this.showBackButton = false;
        },
    },
    computed: {
        visibleButtons() {
            return Object.keys(this.buttons).filter((button) => this.buttons[button]);
        },
    },
    mounted() { },
};
</script>

<style>

</style>
