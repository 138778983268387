<template>


  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Shigikira</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">



      <div class="card-igisomwa">
        <h2>Shigikira igikorwa c'Imana</h2>
        <pre>Uhora uhezagirwa na Pastor NIKIZA Jean-Apôtre canke na green pastures urugendo rwo gusoma Bibiliya yose mumwaka wose??
                    
Urakunda ubutumwa bwiza kandi ushaka ko bwoshikira abatarashikirwa??
        
Uhora usengera ishengero, umugeni wa Kristo kugira ashike kugusa na Kristo hamwe no kugutunganwa gose??</pre>
        <h3>Ukoresheje ifaranga ryawe shigikira Pastor NIKIZA Jean-Apôtre na green pastures witabire igikorwa gikomeye
          c'Imana ikomeye.</h3>
        <br>
        <a
          href="https://www.gofundme.com/f/donate-to-reach-more-people-with-the-gospel?utm_campaign=p_cp+share-sheet&utm_medium=copy_link_all&utm_source=customer">
          <OurButton>GoFundMe</OurButton>
        </a>
        <br>

        <p><a href="tel:+257 65281527">Lumicash: (+257)65281527</a></p>
        <p><a href="tel:+257 76780529">Pastor NIKIZA J-A Ecocash: (+257)76780529</a></p>

        <p><a href="tel:+257 76592886">Arielle NIKIZA +257 76592886</a></p>

        <p><a href="mailto:nik2flock@gmail.com">nik2flock@gmail.com <i class="icon-email" aria-hidden="true"></i></a>
        </p>
        <p><a href="mailto:arielle2flock@gmail.com">arielle2flock@gmail.com <i class="icon-email"
              aria-hidden="true"></i></a></p>

      </div>
      <br>
      <br>
      <br>
      <br>
    </ion-content>
  </ion-page>
</template>

<script>

import OurButton from '@/components/OurButton.vue'

export default {
  name: 'ShigikiraView',
  components: {
    OurButton  },
}
</script>

<style scoped>
p{
  margin: 4px;
}
</style>
