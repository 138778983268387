<template>
  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Rondera</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">

      <div class="sides">
        <div class="toggle-switch">
          <div class="toggle-options">
            <button @click="setFirst" :class="{ 'active': !toggle }">Bibiliya yose mu mwaka wose</button>
            <button @click="setSecond" :class="{ 'active': toggle }">Isezerano rishasha na Zaburi</button>
            <div class="slider"></div>
          </div>
        </div>

        <div v-if="toggle">
          <!-- Content for the second state -->
          <Rondera_2year />
        </div>
        <div v-else>
          <!-- Content for the first state -->
          <Rondera_1year />
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script>
import Rondera_1year from '@/components/Rondera_1year.vue'
import Rondera_2year from '@/components/Rondera_2year.vue'

export default {
  name: 'rondera-igisomwa',
  components: {
    Rondera_1year,
    Rondera_2year
  },
  data() {
    return {
      toggle: false,
    }
  },
  methods: {
    setFirst() {
      this.toggle = false;
    },
    setSecond() {
      this.toggle = true;
    }
  }
}
</script>


<style></style>