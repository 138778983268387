<template>


  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Green Pastures</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">

      <br>
      <br>
      <br>
      <div class="ntagisomwa-na-footer">
        <h3>404</h3>
        <h3>Ntanyishu ihari</h3>
        <h3>:(</h3>
      </div>
      <br>
      <br>
      <br>
      <br>
    </ion-content>
  </ion-page>
</template>

<script>

export default {
  name: 'AboutView',
  components: {
  },
}
</script>