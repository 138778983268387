import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import IbibazoView from '../views/IbibazoView.vue'
import RonderaView from '../views/RonderaView.vue'
import ShigikiraView from '../views/ShigikiraView.vue'
import SangizaView from '../views/SangizaView.vue'
import IgisomwaView from '../views/IgisomwaView.vue'
import Meditations_igisomwaView from '../views/Meditations_igisomwaView.vue'
import MeditationsView from '../views/MeditationsView.vue'
import MeditationDetailView from '../views/MeditationDetailView.vue'
import IntroView from '../views/IntroView.vue'
import IntroDetailView from '../views/IntroDetailView.vue'
import PathNotFoundView from '../views/PathNotFoundView.vue'


const routes =  [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/ibibazo',
    name: 'ibibazo',
    component: IbibazoView
  },
  {
    path: '/rondera',
    name: 'rondera',
    component: RonderaView
  },
  {
    path: '/shigikira',
    name: 'shigikira',
    component: ShigikiraView
  },
  {
    path: '/sangiza',
    name: 'sangiza',
    component: SangizaView
  },
  {
    path: '/igisomwa/:id',
    name: 'igisomwa',
    component: IgisomwaView
  },
  {
    path: '/meditations_igisomwa/:id',
    name: 'meditations_igisomwa',
    component: Meditations_igisomwaView
  },
  {
    path: '/meditations',
    name: 'meditations',
    component: MeditationsView
  },
  {
    path: '/meditationDetail/:id',
    name: 'meditationDetail',
    component: MeditationDetailView
  },
  {
    path: '/intro',
    name: 'intro',
    component: IntroView
  },
  {
    path: '/IntroDetail/:id',
    name: 'IntroDetail',
    component: IntroDetailView
  },
  { 
    path: '/:pathMatch(.*)*',
    component: PathNotFoundView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
