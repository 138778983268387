<template>
  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Ibibazo</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">



      <div v-if="loader && this.$store.state.ibibazo == 0">
        <div class="card-igisomwa">
          <OurLoading />
        </div>
      </div>

      <div v-else class="card-igisomwa">
        <h2>Ibibazo bikunda kubazwa</h2>

        <div v-for="(ikibazo, index) in this.$store.state.ibibazo" :key="index" class="faq-item">
          <h2 class="question" @click="toggleAnswer(index)">{{ ikibazo.ikibazo }}</h2>
          <div v-show="ikibazo.showAnswer" class="answer">
            <pre v-html="formatInyishu(ikibazo.inyishu)"></pre>
          </div>
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import OurLoading from '@/components/OurLoading.vue'

export default {
  name: 'AboutView',
  components: {
    OurLoading
  },
  data() {
    return {
      loader: true,
    }
  },
  methods: {
    loadibibazo() {
      this.axios.get(this.$store.state.baseUrl + 'ibibazo/').then(
        response => {
          this.$store.state.ibibazo = response.data.results;
          this.loader = false;
        }
      ) //.finally(this.loadTwebwe())
    },
    toggleAnswer(index) {
      this.$store.state.ibibazo[index].showAnswer = !this.$store.state.ibibazo[index].showAnswer;
    },
    formatInyishu(text) {
      // Regular expression to identify links
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      // Replace the links with anchor tags
      return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    }

  },
  mounted() {
    this.loadibibazo();
  },

}
</script>

<style>
.faq-item {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 0 1rem 0;
  padding: 1rem;
}

.question {
  font-size: 20px;
  color: var(--text_Color);
  margin: 0;
}

.answer {
  display: none;
}


/* Show/hide functionality for the answers */
.faq-item:hover .answer {
  display: block;
}
</style>