<template>
    <ion-page id="main-content">

        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button text="" defaultHref="/"></ion-back-button>
                </ion-buttons>
                <ion-title>Intangamarara zose</ion-title>
                <ion-buttons slot="end">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">

            <div v-if="loader">
                <OurLoading />
            </div>


            <div v-for="(item, index) in intangamarara" :key="index">
                <router-link class="link-rondera" :to="`/introDetail/${item.id}`">
                    <ion-list>
                        <ion-item detail='true'>
                            <ion-label>
                                <h2>{{ item.igitabo }}</h2>
                                <p>{{ item.message.slice(0, 40) + "..." }}</p>
                            </ion-label>
                        </ion-item>
                    </ion-list>

                </router-link>
            </div>

        </ion-content>
    </ion-page>
</template>


<script>
import OurLoading from '@/components/OurLoading.vue';

export default {
    name: 'rondera-meditation',
    components: {
        OurLoading
    },
    data() {
        return {
            loader: false,
            intangamarara: [],
        };
    },
    methods: {
        getIntro() {
            this.axios
                .get(this.$store.state.baseUrl + `v2/intangamarara/`)
                .then((response) => {
                    this.intangamarara = response?.data?.results;
                    this.loader = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loader = false; // Turn off loader in case of error
                });
        },
    },
    computed: {
    },
    mounted() {
        this.getIntro()
    },
};
</script>

<style>
.meditation {
    margin: 1rem 0 0 0;
}
</style>
