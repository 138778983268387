<template>

  <ion-app>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />

    <router-view id="main-content" />
    <ion-menu content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title><img
            src="/static/logo.png" loading="lazy" class="logo" alt="logo" />
          </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>

        <router-link class="link-menu" :to="`/`" @click="closeMenu">
          <ion-item detail='true'>
            <ion-label>Ikaze</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-menu" :to="`/about`" @click="closeMenu">
          <ion-item detail='true'>
            <ion-label>Twebwe</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-menu" :to="`/ibibazo`" @click="closeMenu">
          <ion-item detail='true'>
            <ion-label>Ibibazo</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-menu" :to="`/rondera`" @click="closeMenu">
          <ion-item detail='true'>
            <ion-label>Rondera</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-menu" :to="`/meditations`" @click="closeMenu">
          <ion-item detail='true'>
            <ion-label>Kurimbura</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-menu" :to="`/intro`" @click="closeMenu">
          <ion-item detail='true'>
            <ion-label>Intangamarara zose</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-menu" :to="`/shigikira`" @click="closeMenu">
          <ion-item detail='true'>
            <ion-label>Shigikira</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-menu" :to="`/sangiza`" @click="closeMenu">
          <ion-item detail='true'>
            <ion-label>Sangiza abandi</ion-label>
          </ion-item>
        </router-link>

        <ion-item>
          <!-- <ion-label>Impinduro 1.0.2</ion-label> --> <!-- web -->
          <!-- <ion-label>Impinduro 1.0.1</ion-label> --> <!-- ios -->
          <ion-label>Impinduro 1.0.4</ion-label> <!-- android -->
        </ion-item>

        <ion-item>
          <ion-label>Yakozwe na <a href="https://www.egeratech.com">egeraTech</a></ion-label>
        </ion-item>

      </ion-list>

      <br>
      <br>

    </ion-content>
  </ion-menu>

  </ion-app>
</template>

<script>
import { IonApp, menuController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useBackButton, useIonRouter } from '@ionic/vue';
import { App } from '@capacitor/app';



export default defineComponent({
  name: 'App',
  components: {
    IonApp,
  
  },
  setup() {
    const ionRouter = useIonRouter();
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
  },
  methods: {
    closeMenu(){
      menuController.close()
    }
  }
});
</script>

<style>
.logo{
  width: 50px;
}
</style>