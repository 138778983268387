<template>
    <div v-if="loader && this.$store.state.igisomwaUnomusi_2year == 0">
        <div class="card-igisomwa">
            <OurLoading />
        </div>
    </div>

    <div v-if="loader == false && this.$store.state.igisomwaUnomusi_2year == 0">
        <br>
        <br>
        <div class="card-igisomwa">
            <h3>Unomusi ntagisomwa gitangwa, ariko urashobora kurondera igisomwa ushaka</h3>
            <button class="submit" @click="rondera">Rondera</button>
        </div>
    </div>


    <div v-for="(intangamarara, index) in this.$store.state.igisomwaUnomusi_2year" :key="index">

        <div class="card-igisomwa">
            <iframe :src="intangamarara.youtube_link" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            <h2>Intangamarara y'igitabo:</h2>
            <h3>{{ intangamarara.igitabo }}</h3>
            <pre>{{ intangamarara.message }}</pre>

            <div v-for="(igisomwa, index) in intangamarara.ibisomwa" :key="index">

                <h3>Itariki {{ moment(igisomwa.post_date).format("DD-MM-YYYY") }}</h3>
                <h3>Indwi ya {{ igisomwa.indwi_ya }}</h3>
                <h3>Umusi wa {{ igisomwa.umusi_wa }}</h3>

                <h2>Igisomwa:</h2>
                <h3>{{ igisomwa.igisomwa }}</h3>

                <h2>Kristo mugisomwa</h2>
                <pre>{{ igisomwa.kristo_mugisomwa }}</pre>

                <h2>Kurimbura</h2>
                <pre v-html="formatInyishu(igisomwa.kurimbura)"></pre>
                <button @click="kurimbura(igisomwa.id)" class="submit">Kurimbura +</button>

                <h2>Isengesho</h2>
                <pre>{{ igisomwa.isengesho }}</pre>

                <div v-if="igisomwa.amasanamu == 0">
                    <!-- <p>Ntasanamu</p> -->
                </div>

                <div v-else>
                    <h2>Amasanamu</h2>
                    <div v-for="(isanamu, index) in igisomwa.amasanamu" :key="index">
                        <img :src="`https://greenpastures.bi${isanamu.isanamu}`" />
                        <p>{{ isanamu.description }}</p>
                    </div>
                </div>

                <form @submit.prevent="updateAbasomyi(igisomwa.id)">
                    <input type="hidden" v-model="abasomyiForm.date">
                    <input type="hidden" v-model="abasomyiForm.ndasomye">
                    <input type="hidden" v-model="abasomyiForm.igisomwa">

                    <!-- <button v-if="ndikondasoma" v-bind:igisomwa_id="igisomwa.id" type="submit" class="submit"> Ndikondasoma </button> -->
                    <button v-if="ndikondasoma" type="submit" class="submit"> Ndikondasoma </button>
                </form>

                <!-- <ion-item>
                    <ion-list>
                        <h3 v-if="igisomwa.abasomyi">Abariko barasoma ni {{ igisomwa.abasomyi.length }}</h3>
                    </ion-list>
                </ion-item> -->

                <h2>Inyandiko zakoreshejwe </h2>
                <pre>{{ igisomwa.inyandiko_zakoreshejwe }}</pre>

                <h2>Ubivugako iki??</h2>

                <form class="from-comment" @submit.prevent="save_comment(igisomwa.id)">
                    <textarea row="5" placeholder="andika ngaha" v-model="formComment.comment" required></textarea>
                    <input type="hidden" v-model="formComment.igisomwa">

                    <input type="hidden" v-model="formComment.date">
                    <input type="hidden" v-model="formComment.published">


                    <button v-bind:igisomwa_id="igisomwa.id" type="submit" class="submit">Rungika</button>
                </form>

                <button type="submit" class="submit" @click="shareContent(igisomwa.id)">Sangiza</button>


                <h2>Ico abandi babivuzeko <span class="comment-date">{{ igisomwa.comments.length }}</span></h2>

                <div v-if="igisomwa.comments == 0">
                    <p>Ntaco abandi barabivugako</p>
                </div>

                <div v-else>

                    <div v-for="(comment, index) in igisomwa.comments" :key="index">
                        <ion-list>
                            <ion-item>
                                <p slot="start" class="start"></p>
                                <div class="card-comments">
                                    <p class="comment-date">{{ moment(comment.date).format("DD-MM-YYYY") }}</p>
                                    <p>{{ comment.comment }}</p>
                                </div>
                            </ion-item>
                        </ion-list>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
  
  
<script>
import moment from 'moment'
import OurLoading from '@/components/OurLoading.vue'


export default {
    name: 'HomeView',
    // props: ['igisomwa_id'],
    components: {
        OurLoading,
    },
    data() {
        return {
            loader: true,
            toggle: false,
            moment: moment,
            formComment: {
                comment: '',
                igisomwa: '',
                date: new Date().toISOString().slice(0, 10), //Current date yyyy-mm-dd
                published: 'true'
            },
            ndikondasoma: true,
            abasomyiForm: {
                date: new Date().toISOString().slice(0, 10),
                ndasomye: true,
                igisomwa: '',
            }
        }
    },
    methods: {
        setFirst() {
            this.toggle = false;
        },
        setSecond() {
            this.toggle = true;
        },
        save_comment(id) {
            this.formComment.igisomwa = `${id}` // getting id from loop
            console.log(this.formComment)
            this.axios.post(this.$store.state.baseUrl + 'ico-abandi-babivuzeko/', this.formComment)
                .then(response => {
                    console.log(response)
                    this.formComment.comment = '';
                    this.getIbisomwa();
                    this.$toast.success(`Warungitse!`)
                }).catch(err => {
                    console.log(err)
                    this.$toast.error(err)
                })
        },
        updateAbasomyi(id) {
            this.ndikondasoma = false;
            this.abasomyiForm.igisomwa = `${id}` // getting id from loop
            console.log(this.abasomyiForm)
            this.axios.post(this.$store.state.baseUrl + 'abasomyi/', this.abasomyiForm)
                .then(response => {
                    console.log(response)
                    this.getIbisomwa();
                    this.$toast.success(`Warungitse!`)
                }).catch(err => {
                    console.log(err)
                    this.$toast.error(err)
                })
        },
        getIbisomwa() {
            this.axios.get(this.$store.state.baseUrl + 'v2/intang-ibisomwa-unomusi-2year/').then(
                resp => {
                    console.log(resp)
                    this.$store.state.igisomwaUnomusi_2year = resp?.data?.results;
                    this.loader = false;
                }).catch(error => {
                    console.log(error)
                })
        },
        formatInyishu(text) {
            // Regular expression to identify links
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            // Replace the links with anchor tags
            return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
        },
        kurimbura(id) {
            this.$router.push(`/meditations_igisomwa/${id}`)
        },
        shareContent(id) {
            if (navigator.share) {
                const shareData = {
                    url: `https://greenpastures.bi/igisomwa/${id}`
                };
                navigator.share(shareData)
                    .then(() => {
                        console.log('Sharing successful');
                    })
                    .catch((error) => {
                        console.error('Error sharing:', error);
                        this.$toast.error('Error sharing:');
                    });
            } else {
                console.log('Web Share API not supported.');
                this.$toast.error('Web Share API not supported.');
            }
        },
        rondera() {
            this.$router.push("/rondera")
        },
    },
    mounted() {
        this.getIbisomwa();
    }
}
</script>
  
  
<style></style>