<template>
  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Kurimbura</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">

      <div v-if="loader && meditation == 0">
        <OurLoading />
      </div>


      <div v-else>
        <div class="card-igisomwa">
          <h2>Kurimbura</h2>
          <pre v-html="formatInyishu(meditation.meditation)"></pre>
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>


<script>
// import axios from 'axios'

import OurLoading from '@/components/OurLoading.vue'


export default {
  name: 'meditation-view',
  // props: ['igisomwa_id'],
  components: {
    OurLoading,
  },
  data() {
    return {
      loader: true,
      meditation: ''
    }
  },
  methods: {
    getMeditation() {
      this.loader = true
      this.axios.get(this.$store.state.baseUrl + 'v2/meditation/' + this.$route.params.id + '/')
        .then(
          resp => {
            console.log(resp)
            this.meditation = resp.data
            this.loader = false
          }).catch(error => {
            console.log(error)
          });
    },
    formatInyishu(text) {
      // Regular expression to identify links
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      // Replace the links with anchor tags
      return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    }

  },
  mounted() {
    this.getMeditation();
  },
  computed: {
  }
}
</script>


<style></style>
