<template>
    <button>
        <slot></slot>
    </button>
</template>

<style scoped>
    button {
        color: #fff;
        padding: 10px;
        border-radius: 4px ;
        background-color: var(--green);
        border: var(--green);
        font-size: 16px;
        box-shadow: 0px 2px 10px #aaa;
    }
    button:hover {
        background-color: #fff;
        color: var(--green);
        box-shadow: 0px 2px 10px #aaa;
        cursor: pointer;
    }
</style>