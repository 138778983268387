<template>
    <div class="card-center">
        <span class="loader"></span>
    </div>
</template>

<style>

.loader {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px var(--green);
    box-sizing: border-box;
    animation: flipX 1s linear infinite;
}


@keyframes flipX {
    0% {
        transform: perspective(200px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(200px) rotateX(-180deg) rotateY(0deg);
    }

    100% {
        transform: perspective(200px) rotateX(-180deg) rotateY(-180deg);
    }
}
</style>