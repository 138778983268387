<template>
  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Green Pastures</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">


      <div class="sides">
        <div class="toggle-switch">
          <div class="toggle-options">
            <button @click="setFirst" :class="{ 'active': !toggle }">Bibiliya yose mu mwaka wose</button>
            <button @click="setSecond" :class="{ 'active': toggle }">Isezerano rishasha na Zaburi</button>
            <div class="slider"></div>
          </div>
        </div>

        <div v-if="toggle">
          <!-- Content for the second state -->
          <Imyaka2 />
        </div>
        <div v-else>
          <!-- Content for the first state -->
          <Umwaka1 />
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>


<script>
import Umwaka1 from '@/components/Umwaka1.vue'
import Imyaka2 from '@/components/Imyaka2.vue'


export default {
  name: 'HomeView',
  // props: ['igisomwa_id'],
  components: {
    Umwaka1,
    Imyaka2
  },
  data() {
    return {
      toggle: false,
    }
  },
  methods: {
    setFirst() {
      this.toggle = false;
    },
    setSecond() {
      this.toggle = true;
    },
  },
  mounted() {
  }
}
</script>


<style></style>