<template>
  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Igisomwa</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">

      <div v-if="loader && igisomwa == 0">
        <div class="card-igisomwa">
          <OurLoading />
        </div>
      </div>


      <div v-else>
        <div class="card-igisomwa">

          <iframe :src="igisomwa.youtube_link" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <h2>Intangamarara y'igitabo:</h2>
          <h3>{{ igisomwa.igitabo }}</h3>
          <pre>{{ igisomwa.message }}</pre>

          <h3>Itariki {{ moment(igisomwa.post_date).format("DD-MM-YYYY") }}</h3>
          <h3>Indwi ya {{ igisomwa.indwi_ya }}</h3>
          <h3>Umusi wa {{ igisomwa.umusi_wa }}</h3>

          <h2>Igisomwa:</h2>
          <h3>{{ igisomwa.igisomwa }}</h3>

          <h2>Kristo mugisomwa</h2>
          <pre>{{ igisomwa.kristo_mugisomwa }}</pre>

          <h2>Kurimbura</h2>
          <pre v-html="formatInyishu(igisomwa.kurimbura)"></pre>
          <button @click="kurimbura(igisomwa.id)" class="submit">Kurimbura +</button>

          <h2>Isengesho</h2>
          <pre>{{ igisomwa.isengesho }}</pre>

          <div v-if="igisomwa.amasanamu == 0">
            <!-- <p>Ntasanamu</p> -->
          </div>

          <div v-else>
            <h2>Amasanamu</h2>
            <img :src="`https://greenpastures.bi${isanamu.isanamu}`" />
            <p>{{ isanamu.description }}</p>
          </div>


          <form @submit.prevent="updateAbasomyi">
            <input type="hidden" v-model="abasomyiForm.date">
            <input type="hidden" v-model="abasomyiForm.ndasomye">
            <input type="hidden" v-model="abasomyiForm.igisomwa">

            <button v-if="ndikondasoma" type="submit" class="submit">Ndikondasoma</button>
          </form>

          <h2>Inyandiko zakoreshejwe</h2>
          <pre>{{ igisomwa.inyandiko_zakoreshejwe }}</pre>

          <h2>Ubivugako iki??</h2>
          <form class="from-comment" @submit.prevent="save_comment">
            <textarea row="5" placeholder="andika ngaha" v-model="formComment.comment" required></textarea>
            <input type="hidden" v-model="formComment.igisomwa">

            <input type="hidden" v-model="formComment.date">
            <input type="hidden" v-model="formComment.published">

            <button type="submit" class="submit">Rungika</button>
          </form>

          <button type="submit" class="submit" @click="shareContent">Sangiza</button>


          <h2>Ico abandi babivuzeko <span v-if="igisomwa.comments" class="comment-date">{{ igisomwa.comments.length
          }}</span></h2>

          <div v-if="igisomwa.comments == 0">
            <p>Ntaco abandi barabivugako</p>
          </div>
          <div v-else>
            <div v-for="(comment, index) in igisomwa.comments" :key="index">
              <ion-list>
                <ion-item>
                  <p slot="start" class="start"></p>
                  <div class="card-comments">
                    <p class="comment-date">{{ moment(comment.date).format("DD-MM-YYYY") }}</p>
                    <p>{{ comment.comment }}</p>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </div>
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>


<script>
// import axios from 'axios'

import OurLoading from '@/components/OurLoading.vue'
import moment from 'moment'


export default {
  name: 'igisomwa-cose',
  // props: ['igisomwa_id'],
  components: {
    OurLoading,
  },
  data() {
    return {
      moment: moment,
      currentIndex: 0,

      igisomwa: [],
      comments: [],
      loader: true,
      formComment: {
        comment: '',
        igisomwa: this.$route.params.id,
        date: new Date().toISOString().slice(0, 10),
        published: 'true'
      },
      ndikondasoma: true,
      abasomyiForm: {
        date: new Date().toISOString().slice(0, 10),
        ndasomye: true,
        igisomwa: this.$route.params.id,
      }
    }
  },
  methods: {
    save_comment() {
      console.log(this.formComment)
      this.axios.post(this.$store.state.baseUrl + 'ico-abandi-babivuzeko/', this.formComment)
        .then(response => {
          console.log(response)
          this.formComment.comment = '';
          this.getIgisomwa();
          this.$toast.success(`Warungitse!`)
        }).catch(err => {
          console.log(err)
          this.$toast.error(err)
        })
    },
    updateAbasomyi() {
      this.ndikondasoma = false;
      console.log(this.formComment)
      this.axios.post(this.$store.state.baseUrl + 'abasomyi/', this.abasomyiForm)
        .then(response => {
          console.log(response)
          this.getIgisomwa();
          this.$toast.success(`Warungitse!`)
        }).catch(err => {
          console.log(err)
          this.$toast.error(err)
        })
    },
    getIgisomwa() {
      this.axios.get(this.$store.state.baseUrl + 'v2/ibisomwa/' + this.$route.params.id + '/')
        .then(
          resp => {
            console.log(resp)
            this.igisomwa = resp.data
          }).catch(error => {
            console.log(error)
          });
    },
    formatInyishu(text) {
      // Regular expression to identify links
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      // Replace the links with anchor tags
      return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    },
    goToNextSlide() {
      if (this.currentIndex < this.igisomwa.length - 1) {
        this.currentIndex++;
      } else {
        // Increment the route param id when moving to the next slide
        const nextId = parseInt(this.$route.params.id) + 1;
        this.$router.push({ name: 'igisomwa', params: { id: nextId } });
      }
    },
    goToPreviousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        // Decrement the route param id when moving to the previous slide
        const prevId = parseInt(this.$route.params.id) - 1;
        if (prevId > 0) {
          this.$router.push({ name: 'igisomwa', params: { id: prevId } });
        }
      }
    },
    kurimbura(id) {
      this.$router.push(`/meditations_igisomwa/${id}`)
    },
    shareContent() {
      if (navigator.share) {
        const shareData = {
          url: `https://greenpastures.bi/igisomwa/${this.$route.params.id}`
        };
        navigator.share(shareData)
          .then(() => {
            console.log('Sharing successful');
          })
          .catch((error) => {
            console.error('Error sharing:', error);
            this.$toast.error('Error sharing:');
          });
      } else {
        console.log('Web Share API not supported.');
        this.$toast.error('Web Share API not supported.');
      }
    }

  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.getIgisomwa();
        this.currentIndex = 0;
      });
    }
  },
  mounted() {
    this.getIgisomwa();
  },
  computed: {
  }
}
</script>


<style></style>
