import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import './assets/global.css'
import '../public/static/icons.css'
import store from './store'
import axios from 'axios'
import mixins from './mixins'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import Toaster from "@meforma/vue-toaster"

/* Theme variables */
import './theme/variables.css';

import { IonPage, IonContent, IonTitle, IonMenuButton, IonBackButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons } from '@ionic/vue';

const components = {
  IonPage, IonContent, IonTitle, IonMenuButton, IonBackButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons,
};



function getCsrfToken() {
  // Replace this logic with your actual code to obtain the CSRF token
  const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
  if (token) {
    return token.split('=')[1];
  }
  return null; // Handle the case where the token is not found
}

const csrfToken = getCsrfToken(); 
axios.defaults.headers.common['X-CSRFToken'] = csrfToken;


const app = createApp(App)

Object.entries(components).forEach(([name, component]) => {
  app.component(name, component)
})


  // app.config.globalProperties.axios =  { ...axiosInstance }
app.config.globalProperties.axios=axios //

app
.use(store)
.use(IonicVue)
.use(router)
.use(Toaster)
// .use(VueAnalytics, { id: 'green_google_id', router })
.mixin(mixins)
  
router.isReady().then(() => {
  app.mount('#app');
});

